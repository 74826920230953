
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { Component, Vue } from 'vue-property-decorator';
import { InputValidationRules } from 'vuetify';
import { SIGN_IN } from '@/constants/router/routes-names';

import background from '@/assets/img/sign-in/background.png';

@Component
export default class SignIn extends Vue {
	valid: boolean = false;
	loading: boolean = false;
	model = { email: '' };
	emailRules: InputValidationRules = [
		(v) => !!v || 'E-mail is required',
		(v) => /.+@.+/.test(v) || 'E-mail must be valid',
	];
	showSnackbar = false;

	sendEmail() {
		this.loading = true;
		if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
			sendPasswordResetEmail(getAuth(), this.model.email);
			this.showSnackbar = true;
			this.loading = false;
		}
	}

	get background(): File {
		return background;
	}

	get loginLink() {
		return { name: SIGN_IN };
	}
}
