var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    { attrs: { id: "signin", dark: "" } },
    [
      _c(
        "v-main",
        [
          _c(
            "v-container",
            { staticClass: "fill-height pa-0", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                { staticClass: "fill-height", attrs: { "no-gutters": "" } },
                [
                  _vm.$vuetify.breakpoint.mdAndUp
                    ? _c(
                        "v-col",
                        {
                          staticClass: "infos-panel",
                          style: {
                            backgroundImage: `url('${_vm.background}')`,
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "logo mt-8 mr-8 float-right",
                            attrs: {
                              src: require("@/assets/img/app/logo.png"),
                              alt: "Tailer Logo",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _c("v-col", { staticClass: "form-panel" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "content d-flex flex-column justify-center",
                      },
                      [
                        _c("h1", { staticClass: "mb-7" }, [
                          _vm._v("Reset your "),
                          _c("span", { staticClass: "primary--text" }, [
                            _vm._v("password"),
                          ]),
                        ]),
                        _c(
                          "v-form",
                          {
                            ref: "form",
                            model: {
                              value: _vm.valid,
                              callback: function ($$v) {
                                _vm.valid = $$v
                              },
                              expression: "valid",
                            },
                          },
                          [
                            _c("v-text-field", {
                              attrs: {
                                name: "email",
                                label: "Email",
                                type: "email",
                                rules: _vm.emailRules,
                                required: "",
                              },
                              model: {
                                value: _vm.model.email,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "email", $$v)
                                },
                                expression: "model.email",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-space-between align-center mt-5",
                          },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "ma-0",
                                attrs: { to: _vm.loginLink },
                              },
                              [_vm._v("Return to login page")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  disabled: !_vm.valid,
                                  loading: _vm.loading,
                                  color: "primary",
                                },
                                on: { click: _vm.sendEmail },
                              },
                              [_vm._v("Send mail")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", {
                      staticClass:
                        "social-authentications d-flex flex-column justify-center align-center",
                    }),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: "success", timeout: 7000 },
          model: {
            value: _vm.showSnackbar,
            callback: function ($$v) {
              _vm.showSnackbar = $$v
            },
            expression: "showSnackbar",
          },
        },
        [_vm._v(" An email has been sent to " + _vm._s(_vm.model.email) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }